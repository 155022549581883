import React from "react"
import PrivateRoute from "../../../components/auth/PrivateRoute/loadable"
import AccountInit from "../../../components/MyAccount/AccountInit"

const AccountInitPage = props => (
  <PrivateRoute url={props.location.href}>
    <AccountInit />
  </PrivateRoute>
)

export default AccountInitPage
