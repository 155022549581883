import React, { useState, useEffect } from "react"
import { connect } from "react-redux"
import { GridBox, Text } from "@thedgbrt/logology-ui-components"
import API, { graphqlOperation } from "@aws-amplify/api"
import {
  checkProfile,
  initProfile,
  fetchProfile,
} from "../../state/actions/user"
import { trackAmplitude } from "../../helpers/amplitude"
import { trackFB } from "../../helpers/fbPixel"
import { trackSegmentEvent } from "../../helpers/segment"

import Loading from "../common/Loading"
import { Link, navigate } from "gatsby"

const AccountInit = ({
  checkProfile,
  fetchProfile,
  initProfile,
  localProfile,
}) => {
  const [error, setError] = useState(false)

  // verify localstorage
  // create profile
  // redirect
  // error, missing data -> logout and restart questionnaire
  useEffect(() => {
    checkProfile(API, graphqlOperation)
      .then(exists => {
        if (exists === true) {
          return fetchProfile(API, graphqlOperation)
        } else {
          trackSegmentEvent("track", "Account init")
          trackAmplitude("Account init")
          trackFB("CompleteRegistration")
          return initProfile(API, localProfile).then(res => {
            if (res === "retake_questionnaire") {
              navigate("/questionnaire/")
              return "stop chain"
            } else return fetchProfile(API, graphqlOperation)
          })
        }
      })
      .then(res => {
        if (res === "stop chain") return
        navigate("/myaccount/proposals/")
      })
      .catch(e => {
        setError(true)
      })
  }, []) // eslint-disable-line

  if (error)
    return (
      <GridBox type={3} colSpans={[[2, 5], [2, 5], [2, 5]]} withBgContainer>
        <Text standalone>
          There was an error initializing your account.{" "}
          <Link to="/signin/">Try logging in again.</Link>
        </Text>
      </GridBox>
    )

  return <Loading />
}
export default connect(
  ({ questionnaire }) => ({
    localProfile: {
      activityId: questionnaire.activityId,
      activityName: questionnaire.activityName,
      activitySelectStage: questionnaire.activitySelectStage,
      answers: questionnaire.answers,
      startupName: questionnaire.startupName,
      personaId: questionnaire.persona,
    },
  }),
  {
    checkProfile,
    fetchProfile,
    initProfile,
  }
)(AccountInit)
